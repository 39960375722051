/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import WeatherCard from "examples/Cards/WeatherCard";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import ReportsDoughnutChart from "examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import ThinBarChart from "examples/Charts/BarCharts/ThinBarChart";
import ControllerCard from "examples/Cards/ControllerCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// SmartHome dashboard components
import Graphs from "layouts/nationalaccount/tradebalance/components/Trades";
import GraphsforEconomicGroup from "layouts/nationalaccount/tradebalance/components/TradesGroup";
import GraphsforTradeIndex from "layouts/nationalaccount/tradebalance/components/TradeIndex";

import TemperatureSlider from "layouts/nationalaccount/tradebalance/components/TemperatureSlider";

// Data
import reportsDoughnutChartData from "layouts/nationalaccount/tradebalance/data/reportsDoughnutChartData";
import thinBarChartData from "layouts/nationalaccount/tradebalance/data/thinBarChartData";
import controllerCardIcons from "layouts/nationalaccount/tradebalance/data/controllerCardIcons";

// Data
import traderawdata from "layouts/nationalaccount/tradebalance/data/tradesrawdata";
import tradegrouprawdata from "layouts/nationalaccount/tradebalance/data/tradesgrouprawdata";
import tradegrouprawdataseries from "layouts/nationalaccount/tradebalance/data/tradesgrouprawdataseries";
import tradeindex from "layouts/nationalaccount/tradebalance/data/tradeindexdata";
//import tradeindexdetail from "layouts/nationalaccount/tradebalance/data/tradeindexdetaildata";

import { getChartData, chartOptions } from  "layouts/nationalaccount/tradebalance/components/data/tradedata";
import { getChartData1 , chartOptions1 } from  "layouts/nationalaccount/tradebalance/components/data/tradedata";
import { getChartData2 , chartOptions2 } from  "layouts/nationalaccount/tradebalance/components/data/tradegroupdata";
import { getChartData2 as getChartData3 , chartOptions3 } from  "layouts/nationalaccount/tradebalance/components/data/tradegroupdata";

import { getChartDataDetail as getChartData4 } from  "layouts/nationalaccount/tradebalance/components/data/tradeindex";

import { getChartDataDetail , chartOptionsDetail } from  "layouts/nationalaccount/tradebalance/components/data/tradedetailgroupdata";


// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";


// Images
import iconSunCloud from "assets/images/small-logos/icon-sun-cloud.png";


function Trades() {

  const [exporimp, setExporImp] = useState(true);
  const handleSetExporImp = () => setExporImp(!exporimp);
  const [summary, setSummary] = useState(true);
  const handleSetSummary = () => setSummary(!summary);


  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const [tabValue, setTabValue] = useState(1);

  const [lastexportvalue, setLastExportValue] = useState(-1);
  const [lastimportvalue, setLastImportValue] = useState(-1);
  const [lastcurrentaccountvalue, setCurrentAccountValue] = useState(-1);
  const [lastexportratio, setExportRatio] = useState(-1);
  const [exportgroup, setExportGroup] = useState(-1);
  const [importgroup, setImportGroup] = useState(-1);
  const [lastgold, setLastGold] = useState(-1);


  const params="monthly"
  const params2="yearly"


  const data_monthly=traderawdata(params)
  const data_yearly=traderawdata(params2)
  const data_group_monthly=tradegrouprawdataseries(params)
  const data_group_yearly=tradegrouprawdataseries(params2)

  const dataindex_monthly=tradeindex(params)
  const dataindex_3monthly=tradeindex(params2)

  const monthlyithalatdata=dataindex_monthly["monthly_ithalat_data"]
  const threemonthlyithalatdata=dataindex_3monthly["three_monthly_ithalat_data"]

  const monthlyihracatdata=dataindex_monthly["monthly_ihracat_data"]
  const threemonthlyihracatdata=dataindex_3monthly["three_monthly_ihracat_data"]

  var monthly_index_data = new Array();
  monthly_index_data['ithalat'] = monthlyithalatdata;
  monthly_index_data['ihracat'] = monthlyihracatdata;

  var three_monthly_index_data = new Array();
  three_monthly_index_data['ithalat'] = threemonthlyithalatdata;
  three_monthly_index_data['ihracat'] = threemonthlyihracatdata;

  console.log(three_monthly_index_data)

  const yatırımmalları=data_group_monthly["import_series"]["Yatırım Malları"]
  const aramallar=data_group_monthly["import_series"]["Ara Mallar"]
  const tuketimmalları=data_group_monthly["import_series"]["Tüketim Malları"]
  const digerleri=data_group_monthly["import_series"]["Diğerleri"]
  const toplam=data_group_monthly["import_series"]["Toplam"]

  const yatırımmalları1=data_group_yearly["import_series"]["Yatırım Malları"]
  const aramallar1=data_group_yearly["import_series"]["Ara Mallar"]
  const tuketimmalları1=data_group_yearly["import_series"]["Tüketim Malları"]
  const digerleri1=data_group_yearly["import_series"]["Diğerleri"]
  const toplam1=data_group_yearly["import_series"]["Toplam"]

  const yatırımmalları2=data_group_monthly["export_series"]["Yatırım Malları"]
  const aramallar2=data_group_monthly["export_series"]["Ara Mallar"]
  const tuketimmalları2=data_group_monthly["export_series"]["Tüketim Malları"]
  const digerleri2=data_group_monthly["export_series"]["Diğerleri"]
  const toplam2=data_group_monthly["export_series"]["Toplam"]

  const yatırımmalları3=data_group_yearly["export_series"]["Yatırım Malları"]
  const aramallar3=data_group_yearly["export_series"]["Ara Mallar"]
  const tuketimmalları3=data_group_yearly["export_series"]["Tüketim Malları"]
  const digerleri3=data_group_yearly["export_series"]["Diğerleri"]
  const toplam3=data_group_yearly["export_series"]["Toplam"]

  //const data4=tradegrouprawdataseries(params2)
  const chardata = getChartData(data_monthly["ihracat"],data_monthly["ithalat"],data_monthly["ticaretdenge"],data_monthly.labels);
  const chardata1 = getChartData(data_yearly["ihracat"],data_yearly["ithalat"],data_yearly["ticaretdenge"],data_yearly.labels);
  const chardata2 = getChartData2(yatırımmalları,aramallar,tuketimmalları,digerleri,toplam,data_group_monthly["labels"]);
  const chardata3 = getChartData3(yatırımmalları1,aramallar1,tuketimmalları1,digerleri1,toplam1,data_group_yearly["labels"]);
  const chardata4 = getChartData2(yatırımmalları2,aramallar2,tuketimmalları2,digerleri2,toplam2,data_group_monthly["labels"]);
  const chardata5 = getChartData3(yatırımmalları3,aramallar3,tuketimmalları3,digerleri3,toplam3,data_group_yearly["labels"]);

  const yatırımmallarıdetail_imp_m=data_group_monthly["import_detail_series"]["1 Yatırım (sermaye) malları"]
  const yatırımmallarıdetail_imp2_m=data_group_monthly["import_detail_series"]["1.1 Yatırım (sermaye) malları (taşımacılık araçları hariç)"]
  const yatırımmallarıdetail_imp3_m=data_group_monthly["import_detail_series"]["1.2 Sanayi ile ilgili taşımacılık araç ve gereçleri"]
  const arammallarıdetail_imp_m=data_group_monthly["import_detail_series"]["2 Hammadde (ara mallar)"]
  const aramallarıdetail_imp2_m=data_group_monthly["import_detail_series"]["2.1 Sanayi için işlem görmemiş hammaddeler"]
  const aramallarıdetail_imp3_m=data_group_monthly["import_detail_series"]["2.2 Sanayi için işlem görmüş hammaddeler"]
  const aramallarıdetail_imp4_m=data_group_monthly["import_detail_series"]["2.3 İşlem görmemiş yakıt ve yağlar"]
  const aramallarıdetail_imp5_m=data_group_monthly["import_detail_series"]["2.4 Yatırım mallarının aksam ve parçaları"]
  const aramallarıdetail_imp6_m=data_group_monthly["import_detail_series"]["2.5 Taşımacılık araçlarının aksam ve parçaları"]
  const aramallarıdetail_imp7_m=data_group_monthly["import_detail_series"]["2.6 Esası yiyecek ve içecek olan işlenmemiş hammadeler"]
  const aramallarıdetail_imp8_m=data_group_monthly["import_detail_series"]["2.7 Esası yiyecek ve içecek olan işlenmiş hammaddeler"]
  const aramallarıdetail_imp9_m=data_group_monthly["import_detail_series"]["2.8 İşlem görmüş diğer yakıt ve yağlar"]
  const tuketimmallarıdetail_imp_m=data_group_monthly["import_detail_series"]["3 Tüketim malları"]
  const tuketimmallarıdetail_imp2_m=data_group_monthly["import_detail_series"]["3.1 Binek otomobilleri"]
  const tuketimmallarıdetail_imp3_m=data_group_monthly["import_detail_series"]["3.2 Dayanıklı tüketim malları"]
  const tuketimmallarıdetail_imp4_m=data_group_monthly["import_detail_series"]["3.3 Yarı dayanıklı tüketim malları"]
  const tuketimmallarıdetail_imp5_m=data_group_monthly["import_detail_series"]["3.4 Dayanıksız tüketim malları"]
  const tuketimmallarıdetail_imp6_m=data_group_monthly["import_detail_series"]["3.5 Esası yiyecek ve içecek olan işlenmemiş tüketim malları"]
  const tuketimmallarıdetail_imp7_m=data_group_monthly["import_detail_series"]["3.6 Esası yiyecek ve içecek olan işlenmiş tüketim malları"]
  const tuketimmallarıdetail_imp8_m=data_group_monthly["import_detail_series"]["3.7 Motor benzini ve diğer hafif yağlar"]
  const tuketimmallarıdetail_imp9_m=data_group_monthly["import_detail_series"]["3.8 Sanayii ile ilgili olmayan taşıma araç ve gereçleri"]
  const digermallarıdetail_imp_m=data_group_monthly["import_detail_series"]["4 Diğerleri"]
  const digermallarıdetail_imp2_m=data_group_monthly["import_detail_series"]["4.1 Başka yerde belirtilmeyen diğer mallar"]

  const yatırımmallarıdetail_imp_y=data_group_yearly["import_detail_series"]["1 Yatırım (sermaye) malları"]
  const yatırımmallarıdetail_imp2_y=data_group_yearly["import_detail_series"]["1.1 Yatırım (sermaye) malları (taşımacılık araçları hariç)"]
  const yatırımmallarıdetail_imp3_y=data_group_yearly["import_detail_series"]["1.2 Sanayi ile ilgili taşımacılık araç ve gereçleri"]
  const arammallarıdetail_imp_y=data_group_yearly["import_detail_series"]["2 Hammadde (ara mallar)"]
  const aramallarıdetail_imp2_y=data_group_yearly["import_detail_series"]["2.1 Sanayi için işlem görmemiş hammaddeler"]
  const aramallarıdetail_imp3_y=data_group_yearly["import_detail_series"]["2.2 Sanayi için işlem görmüş hammaddeler"]
  const aramallarıdetail_imp4_y=data_group_yearly["import_detail_series"]["2.3 İşlem görmemiş yakıt ve yağlar"]
  const aramallarıdetail_imp5_y=data_group_yearly["import_detail_series"]["2.4 Yatırım mallarının aksam ve parçaları"]
  const aramallarıdetail_imp6_y=data_group_yearly["import_detail_series"]["2.5 Taşımacılık araçlarının aksam ve parçaları"]
  const aramallarıdetail_imp7_y=data_group_yearly["import_detail_series"]["2.6 Esası yiyecek ve içecek olan işlenmemiş hammadeler"]
  const aramallarıdetail_imp8_y=data_group_yearly["import_detail_series"]["2.7 Esası yiyecek ve içecek olan işlenmiş hammaddeler"]
  const aramallarıdetail_imp9_y=data_group_yearly["import_detail_series"]["2.8 İşlem görmüş diğer yakıt ve yağlar"]
  const tuketimmallarıdetail_imp_y=data_group_yearly["import_detail_series"]["3 Tüketim malları"]
  const tuketimmallarıdetail_imp2_y=data_group_yearly["import_detail_series"]["3.1 Binek otomobilleri"]
  const tuketimmallarıdetail_imp3_y=data_group_yearly["import_detail_series"]["3.2 Dayanıklı tüketim malları"]
  const tuketimmallarıdetail_imp4_y=data_group_yearly["import_detail_series"]["3.3 Yarı dayanıklı tüketim malları"]
  const tuketimmallarıdetail_imp5_y=data_group_yearly["import_detail_series"]["3.4 Dayanıksız tüketim malları"]
  const tuketimmallarıdetail_imp6_y=data_group_yearly["import_detail_series"]["3.5 Esası yiyecek ve içecek olan işlenmemiş tüketim malları"]
  const tuketimmallarıdetail_imp7_y=data_group_yearly["import_detail_series"]["3.6 Esası yiyecek ve içecek olan işlenmiş tüketim malları"]
  const tuketimmallarıdetail_imp8_y=data_group_yearly["import_detail_series"]["3.7 Motor benzini ve diğer hafif yağlar"]
  const tuketimmallarıdetail_imp9_y=data_group_yearly["import_detail_series"]["3.8 Sanayii ile ilgili olmayan taşıma araç ve gereçleri"]
  const digermallarıdetail_imp_y=data_group_yearly["import_detail_series"]["4 Diğerleri"]
  const digermallarıdetail_imp2_y=data_group_yearly["import_detail_series"]["4.1 Başka yerde belirtilmeyen diğer mallar"]

  const yatırımmallarıdetail_exp_m=data_group_monthly["export_detail_series"]["1 Yatırım (sermaye) malları"]
  const yatırımmallarıdetail_exp2_m=data_group_monthly["export_detail_series"]["1.1 Yatırım (sermaye) malları (taşımacılık araçları hariç)"]
  const yatırımmallarıdetail_exp3_m=data_group_monthly["export_detail_series"]["1.2 Sanayi ile ilgili taşımacılık araç ve gereçleri"]
  const arammallarıdetail_exp_m=data_group_monthly["export_detail_series"]["2 Hammadde (ara mallar)"]
  const aramallarıdetail_exp2_m=data_group_monthly["export_detail_series"]["2.1 Sanayi için işlem görmemiş hammaddeler"]
  const aramallarıdetail_exp3_m=data_group_monthly["export_detail_series"]["2.2 Sanayi için işlem görmüş hammaddeler"]
  const aramallarıdetail_exp4_m=data_group_monthly["export_detail_series"]["2.3 İşlem görmemiş yakıt ve yağlar"]
  const aramallarıdetail_exp5_m=data_group_monthly["export_detail_series"]["2.4 Yatırım mallarının aksam ve parçaları"]
  const aramallarıdetail_exp6_m=data_group_monthly["export_detail_series"]["2.5 Taşımacılık araçlarının aksam ve parçaları"]
  const aramallarıdetail_exp7_m=data_group_monthly["export_detail_series"]["2.6 Esası yiyecek ve içecek olan işlenmemiş hammadeler"]
  const aramallarıdetail_exp8_m=data_group_monthly["export_detail_series"]["2.7 Esası yiyecek ve içecek olan işlenmiş hammaddeler"]
  const aramallarıdetail_exp9_m=data_group_monthly["export_detail_series"]["2.8 İşlem görmüş diğer yakıt ve yağlar"]
  const tuketimmallarıdetail_exp_m=data_group_monthly["export_detail_series"]["3 Tüketim malları"]
  const tuketimmallarıdetail_exp2_m=data_group_monthly["export_detail_series"]["3.1 Binek otomobilleri"]
  const tuketimmallarıdetail_exp3_m=data_group_monthly["export_detail_series"]["3.2 Dayanıklı tüketim malları"]
  const tuketimmallarıdetail_exp4_m=data_group_monthly["export_detail_series"]["3.3 Yarı dayanıklı tüketim malları"]
  const tuketimmallarıdetail_exp5_m=data_group_monthly["export_detail_series"]["3.4 Dayanıksız tüketim malları"]
  const tuketimmallarıdetail_exp6_m=data_group_monthly["export_detail_series"]["3.5 Esası yiyecek ve içecek olan işlenmemiş tüketim malları"]
  const tuketimmallarıdetail_exp7_m=data_group_monthly["export_detail_series"]["3.6 Esası yiyecek ve içecek olan işlenmiş tüketim malları"]
  const tuketimmallarıdetail_exp8_m=data_group_monthly["export_detail_series"]["3.7 Motor benzini ve diğer hafif yağlar"]
  const tuketimmallarıdetail_exp9_m=data_group_monthly["export_detail_series"]["3.8 Sanayii ile ilgili olmayan taşıma araç ve gereçleri"]
  const digermallarıdetail_exp_m=data_group_monthly["export_detail_series"]["4 Diğerleri"]
  const digermallarıdetail_exp2_m=data_group_monthly["export_detail_series"]["4.1 Başka yerde belirtilmeyen diğer mallar"]

  const yatırımmallarıdetail_exp_y=data_group_yearly["export_detail_series"]["1 Yatırım (sermaye) malları"]
  const yatırımmallarıdetail_exp2_y=data_group_yearly["export_detail_series"]["1.1 Yatırım (sermaye) malları (taşımacılık araçları hariç)"]
  const yatırımmallarıdetail_exp3_y=data_group_yearly["export_detail_series"]["1.2 Sanayi ile ilgili taşımacılık araç ve gereçleri"]
  const arammallarıdetail_exp_y=data_group_yearly["export_detail_series"]["2 Hammadde (ara mallar)"]
  const aramallarıdetail_exp2_y=data_group_yearly["export_detail_series"]["2.1 Sanayi için işlem görmemiş hammaddeler"]
  const aramallarıdetail_exp3_y=data_group_yearly["export_detail_series"]["2.2 Sanayi için işlem görmüş hammaddeler"]
  const aramallarıdetail_exp4_y=data_group_yearly["export_detail_series"]["2.3 İşlem görmemiş yakıt ve yağlar"]
  const aramallarıdetail_exp5_y=data_group_yearly["export_detail_series"]["2.4 Yatırım mallarının aksam ve parçaları"]
  const aramallarıdetail_exp6_y=data_group_yearly["export_detail_series"]["2.5 Taşımacılık araçlarının aksam ve parçaları"]
  const aramallarıdetail_exp7_y=data_group_yearly["export_detail_series"]["2.6 Esası yiyecek ve içecek olan işlenmemiş hammadeler"]
  const aramallarıdetail_exp8_y=data_group_yearly["export_detail_series"]["2.7 Esası yiyecek ve içecek olan işlenmiş hammaddeler"]
  const aramallarıdetail_exp9_y=data_group_yearly["export_detail_series"]["2.8 İşlem görmüş diğer yakıt ve yağlar"]
  const tuketimmallarıdetail_exp_y=data_group_yearly["export_detail_series"]["3 Tüketim malları"]
  const tuketimmallarıdetail_exp2_y=data_group_yearly["export_detail_series"]["3.1 Binek otomobilleri"]
  const tuketimmallarıdetail_exp3_y=data_group_yearly["export_detail_series"]["3.2 Dayanıklı tüketim malları"]
  const tuketimmallarıdetail_exp4_y=data_group_yearly["export_detail_series"]["3.3 Yarı dayanıklı tüketim malları"]
  const tuketimmallarıdetail_exp5_y=data_group_yearly["export_detail_series"]["3.4 Dayanıksız tüketim malları"]
  const tuketimmallarıdetail_exp6_y=data_group_yearly["export_detail_series"]["3.5 Esası yiyecek ve içecek olan işlenmemiş tüketim malları"]
  const tuketimmallarıdetail_exp7_y=data_group_yearly["export_detail_series"]["3.6 Esası yiyecek ve içecek olan işlenmiş tüketim malları"]
  const tuketimmallarıdetail_exp8_y=data_group_yearly["export_detail_series"]["3.7 Motor benzini ve diğer hafif yağlar"]
  const tuketimmallarıdetail_exp9_y=data_group_yearly["export_detail_series"]["3.8 Sanayii ile ilgili olmayan taşıma araç ve gereçleri"]
  const digermallarıdetail_exp_y=data_group_yearly["export_detail_series"]["4 Diğerleri"]
  const digermallarıdetail_exp2_y=data_group_yearly["export_detail_series"]["4.1 Başka yerde belirtilmeyen diğer mallar"]

  const chardatadetail_imp_m = getChartDataDetail(yatırımmallarıdetail_imp_m,yatırımmallarıdetail_imp2_m,yatırımmallarıdetail_imp3_m,
                                            arammallarıdetail_imp_m,aramallarıdetail_imp2_m,aramallarıdetail_imp3_m,
                                            aramallarıdetail_imp4_m,aramallarıdetail_imp5_m,aramallarıdetail_imp6_m,aramallarıdetail_imp7_m,
                                            aramallarıdetail_imp8_m,aramallarıdetail_imp9_m,tuketimmallarıdetail_imp_m,tuketimmallarıdetail_imp2_m,
                                            tuketimmallarıdetail_imp3_m,tuketimmallarıdetail_imp4_m,tuketimmallarıdetail_imp5_m,tuketimmallarıdetail_imp6_m,
                                            tuketimmallarıdetail_imp7_m,tuketimmallarıdetail_imp8_m,tuketimmallarıdetail_imp9_m,digermallarıdetail_imp_m,
                                            digermallarıdetail_imp2_m,data_group_monthly["labels"]);


  const chardatadetail_imp_y = getChartDataDetail(yatırımmallarıdetail_imp_y,yatırımmallarıdetail_imp2_y,yatırımmallarıdetail_imp3_y,
                                            arammallarıdetail_imp_y,aramallarıdetail_imp2_y,aramallarıdetail_imp3_y,
                                            aramallarıdetail_imp4_y,aramallarıdetail_imp5_y,aramallarıdetail_imp6_y,aramallarıdetail_imp7_y,
                                            aramallarıdetail_imp8_y,aramallarıdetail_imp9_y,tuketimmallarıdetail_imp_y,tuketimmallarıdetail_imp2_y,
                                            tuketimmallarıdetail_imp3_y,tuketimmallarıdetail_imp4_y,tuketimmallarıdetail_imp5_y,tuketimmallarıdetail_imp6_y,
                                            tuketimmallarıdetail_imp7_y,tuketimmallarıdetail_imp8_y,tuketimmallarıdetail_imp9_y,digermallarıdetail_imp_y,
                                            digermallarıdetail_imp2_y,data_group_yearly["labels"]);


  const chardatadetail_exp_m = getChartDataDetail(yatırımmallarıdetail_exp_m,yatırımmallarıdetail_exp2_m,yatırımmallarıdetail_exp3_m,
                                            arammallarıdetail_exp_m,aramallarıdetail_exp2_m,aramallarıdetail_exp3_m,
                                            aramallarıdetail_exp4_m,aramallarıdetail_exp5_m,aramallarıdetail_exp6_m,aramallarıdetail_exp7_m,
                                            aramallarıdetail_exp8_m,aramallarıdetail_exp9_m,tuketimmallarıdetail_exp_m,tuketimmallarıdetail_exp2_m,
                                            tuketimmallarıdetail_exp3_m,tuketimmallarıdetail_exp4_m,tuketimmallarıdetail_exp5_m,tuketimmallarıdetail_exp6_m,
                                            tuketimmallarıdetail_exp7_m,tuketimmallarıdetail_exp8_m,tuketimmallarıdetail_exp9_m,digermallarıdetail_exp_m,
                                            digermallarıdetail_exp2_m,data_group_monthly["labels"]);


  const chardatadetail_exp_y = getChartDataDetail(yatırımmallarıdetail_exp_y,yatırımmallarıdetail_exp2_y,yatırımmallarıdetail_exp3_y,
                                            arammallarıdetail_exp_y,aramallarıdetail_exp2_y,aramallarıdetail_exp3_y,
                                            aramallarıdetail_exp4_y,aramallarıdetail_exp5_y,aramallarıdetail_exp6_y,aramallarıdetail_exp7_y,
                                            aramallarıdetail_exp8_y,aramallarıdetail_exp9_y,tuketimmallarıdetail_exp_y,tuketimmallarıdetail_exp2_y,
                                            tuketimmallarıdetail_exp3_y,tuketimmallarıdetail_exp4_y,tuketimmallarıdetail_exp5_y,tuketimmallarıdetail_exp6_y,
                                            tuketimmallarıdetail_exp7_y,tuketimmallarıdetail_exp8_y,tuketimmallarıdetail_exp9_y,digermallarıdetail_exp_y,
                                            digermallarıdetail_exp2_y,data_group_yearly["labels"]);

  //const aramallardetail=data3["import_detail_series"]["Ara Mallar"]
  //const tuketimmallarıdetail=data3["import_detail_series"]["Tüketim Malları"]
  //const digerleridetail=data3["import_detail_series"]["Diğerleri"]
  //const toplamdetail=data3["import_detail_series"]["Toplam"]

/*




*/

  var monthlytrade = new Array();
  monthlytrade['ithalat'] = chardata2;
  monthlytrade['ihracat'] = chardata4;
  var yearlytrade = new Array();
  yearlytrade['ithalat'] = chardata3;
  yearlytrade['ihracat'] = chardata5;

  var monthlytrade_detail = new Array();
  monthlytrade_detail['ithalat'] = chardatadetail_imp_m;
  monthlytrade_detail['ihracat'] = chardatadetail_exp_m;

  var yearlytrade_detail  = new Array();
  yearlytrade_detail['ithalat'] = chardatadetail_imp_y;
  yearlytrade_detail['ihracat'] = chardatadetail_exp_y;
  //console.log(monthlytrade)
  //console.log(chardata2)
  //console.log(chardata3)
  //console.log(chartOptions2)
  //console.log(chartOptions3)

  var trade_m = new Array();
  trade_m["summary"]=monthlytrade
  trade_m["detail"]=monthlytrade_detail

  var trade_y = new Array();
  trade_y["summary"]=yearlytrade
  trade_y["detail"]=yearlytrade_detail

  const sonayihracat=data_monthly["sonayihracat"]
  const sonayithalat=data_monthly["sonayithalat"]
  const sonayticaretdenge=data_monthly["sonayticaretdenge"]
  const exportratio=100*sonayihracat/sonayithalat

  const sonayihracat1=data_yearly["sonayihracat"]
  const sonayithalat1=data_yearly["sonayithalat"]
  const sonayticaretdenge1=data_yearly["sonayticaretdenge"]
  const exportratio1=100*sonayihracat1/sonayithalat1

  const datagroup=tradegrouprawdata()
  const monthlyexportgroup=datagroup["monthlyexport"]
  const yearlyexportgroup=datagroup["yearlyexport"]
  const monthlyimportgroup=datagroup["monthlyimport"]
  const yearlyimportgroup=datagroup["yearlyimport"]

  const monthlygold=data_monthly["altın"]
  const yearlygold=data_yearly["altın"]

  const handleSetTabValue = (event, newTabValue) => {
  setTabValue(newTabValue);
  if (newTabValue==0) {
     setLastExportValue(sonayihracat)
     setLastImportValue(sonayithalat)
     setCurrentAccountValue(sonayticaretdenge)
     setExportRatio(exportratio)
     setExportGroup(monthlyexportgroup)
     setImportGroup(monthlyimportgroup)
     setLastGold(monthlygold)
  } else if (newTabValue==1)  {
     setLastExportValue(sonayihracat1)
     setLastImportValue(sonayithalat1)
     setCurrentAccountValue(sonayticaretdenge1)
     setExportRatio(exportratio1)
     setExportGroup(yearlyexportgroup)
     setImportGroup(yearlyimportgroup)
     setLastGold(yearlygold)
  }
  }
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={0}>
        <SoftBox mb={1.5}>
          <Grid container spacing={1}>
           <Grid item   xs={12} xl={5}  sx={{ ml: 100 }}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <SoftBox width="100%">
                    <AppBar position="static">
                      <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}  onLoad={handleSetTabValue}>
                        <Tab label="Aylık" />
                        <Tab label="Yıllık" />
                    </Tabs>
                   </AppBar>
                  </SoftBox>
                 </SoftBox>
                 </Grid>
            <Grid item xs={12} xl={7}>
              <Graphs chartdata={chardata}  chartOptions={chartOptions} chartdata1={chardata1}  chartOptions1={chartOptions1}  tabValue={tabValue}/>
            </Grid>
            <Grid item xs={12} xl={5}>
              <Grid container spacing={0.8}>
                <Grid item xs={12} md={6}>
                  <DefaultCounterCard
                    count={lastexportvalue==-1 ? sonayihracat1  : lastexportvalue }
                    suffix={<>USD</>}
                    title="İhracat"
                    description=""
                    color={"color1"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultCounterCard
                    count={lastimportvalue==-1 ? sonayithalat1  : lastimportvalue}
                    suffix={<>USD</>}
                    title="İthalat"
                    description=""
                    color={"color2"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultCounterCard
                    count={lastcurrentaccountvalue==-1 ? sonayticaretdenge1  : lastcurrentaccountvalue }
                    suffix={<>USD</>}
                    title="Dış Denge"
                    description=""
                    color={"color4"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultCounterCard
                    count={lastexportratio==-1 ? exportratio1  : lastexportratio}
                    suffix="%"
                    title="İhracat/İthalat"
                    description=""
                    color={"text"}
                  />
                </Grid>

             <Grid item xs={12} lg={12}>
              <ReportsDoughnutChart
                title="Ekonomik Grupların Sınıflandırmasına Göre"
                count={{ number: "", text: "" }}
                chart={importgroup==-1 ? yearlyimportgroup :importgroup}
                tooltip="İthalat"
                color="color1"
                text="İthalat"
              />
            </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>

            <Grid item xs={12} sm={6} lg={12}>
              <ThinBarChart color="color5" title="Parasal Olmayan Altın" chart={lastgold==-1 ? yearlygold :lastgold} />
            </Grid>

            </Grid>

            <Grid item xs={12} sm={6} lg={5}>
              <ReportsDoughnutChart
                title="Ekonomik Grupların Sınıflandırmasına Göre"
                count={{ number: "", text: "" }}
                chart={exportgroup==-1 ? yearlyexportgroup :exportgroup}
                tooltip="İhracat"
                color="color2"
                text="İhracat"
              />
            </Grid>

          </Grid>
        </SoftBox>
      </SoftBox>
      <SoftBox my={6} width="100%">
        <Divider />
      </SoftBox>
       <SoftBox pt={0}>
        <SoftBox mb={1.5}>
          <Grid container spacing={3} alignItems="center" mb={3} >
          <Grid item>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftTypography variant="caption" fontWeight="bold">
                {exporimp ? "İthalat": "İhracat"}
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={exporimp} onChange={handleSetExporImp} />
              </SoftBox>
            </SoftBox>
          </Grid>
     <Grid item>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftTypography variant="caption" fontWeight="bold">
                {summary ? "Özet": "Detay"}
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={summary} onChange={handleSetSummary} />
              </SoftBox>
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
               <Tab label="Aylık" />
               <Tab label="Yıllık" />
              </Tabs>
            </AppBar>
          </Grid>
            <Grid item xs={12} xl={12}>
              <GraphsforEconomicGroup chartdata={trade_m}  chartOptions={chartOptions3} chartdata1={trade_y}  chartOptions1={chartOptions3}  tabValue={tabValue} exporimp={exporimp} summary={summary}/>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
        </SoftBox>
      </SoftBox>

      <SoftBox my={6} width="100%">
        <Divider />
      </SoftBox>
       <SoftBox pt={0}>
        <SoftBox mb={1.5}>
          <Grid container spacing={3} alignItems="center" mb={3} >
          <Grid item>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftTypography variant="caption" fontWeight="bold">
                {exporimp ? "İthalat": "İhracat"}
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={exporimp} onChange={handleSetExporImp} />
              </SoftBox>
            </SoftBox>
          </Grid>





          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
               <Tab label="Aylık" />
               <Tab label="3 Aylık" />
              </Tabs>
            </AppBar>
          </Grid>
            <Grid item xs={12} xl={12}>
              <GraphsforTradeIndex chartdata={monthly_index_data}  chartdata1={three_monthly_index_data}   tabValue={tabValue} exporimp={exporimp} summary={summary}/>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
        </SoftBox>
      </SoftBox>

     <Footer />
    </DashboardLayout>
  );
}

export default Trades;
