/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// @mui icons
import ContactMailIcon from '@mui/icons-material/ContactMail';
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

function Footer() {
  return (
    <SoftBox component="footer" py={2}>
      <Grid container justifyContent="center">
      <Grid item xs={12} lg={8}>
          <SoftBox display="flex" justifyContent="center" mt={1} mb={1}>
            <SoftBox mr={3} color="secondary">
          <SoftTypography variant="button" fontWeight="medium">
            <Mailto email="info@datawais.com" subject="" body="">
              <ContactMailIcon fontSize="large" />
           </Mailto>
          </SoftTypography>
            </SoftBox>
            <SoftBox mr={3} color="secondary">
             <Link href={"https://twitter.com/datawais"} target="_blank">
          <SoftTypography variant="button" fontWeight="medium">
              <TwitterIcon fontSize="large" />
          </SoftTypography>
        </Link>
           </SoftBox>
            <SoftBox color="secondary">
          <Link href={""} target="_blank">
          <SoftTypography variant="button" fontWeight="medium">
              <LinkedInIcon fontSize="large" />
          </SoftTypography>
        </Link>            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          <SoftTypography variant="body2" color="secondary">
            Copyright &copy; 2024 Software by Datawais
          </SoftTypography>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Footer;
